<script setup>
import TopMenuFullWidth from "../Components/TopMenuFullWidth.vue";
import { Head, usePage } from "@inertiajs/vue3";
import Footer from "../Components/Footer.vue";
import Toast from "../Components/Toast.vue";
import { inject, onMounted } from "vue";

let isLocalDebug = false;
if (import.meta.env.VITE_APP_ENV === "local") {
    isLocalDebug = true;
}

defineProps({
    title: String, // uzmanīgi, neielaist te XSS
    topHeaderFullWidth: {
        type: Boolean,
        default: false,
    },
    topHeaderBgClass: {
        type: String,
        default: "bg-white",
    },
    htmlClasses: {
        // type: [],
        default: ["flex-1", "py-4 pb-8"],
    },
});

onMounted(() => {
    if (usePage().props.user) {
        /*onLoad(() => {
            setTimeout(function () {
                if (usePage().props.user) {
                    setAttributes(
                        {
                            id: usePage().props.user.id,
                            name: usePage().props.user.name,
                            email: usePage().props.user.email,
                            hash: usePage().props.tawk_to_hash,
                        },
                        function (error) {
                            // do something if error
                        },
                    );
                }
            }, 500);
        });*/
    }
});
</script>

<template>
    <a
        v-if="$page.props.user && $page.props.user.is_impersonated"
        :class="isLocalDebug ? 'bg-red-400 hover:bg-red-800' : ''"
        class="absolute z-50 block w-full bg-blue-700 text-center text-xs font-semibold text-white visited:text-white hover:bg-blue-800"
        href="/impersonate/leave"
    >
        Impersonating {{ $page.props.user.id }}: {{ $page.props.user.name }} (Leave)
    </a>
    <Toast />
    <Head :title="title">
        <meta
            name="description"
            head-key="description"
            content="Your one-stop solution for organizing and managing navigation events of any size"
        />

        <meta
            v-if="$page.props.ziggy"
            property="og:url"
            head-key="og:url"
            :content="$page.props.ziggy.location"
        />
        <meta
            v-if="title"
            property="og:title"
            head-key="og:title"
            :content="title"
        />

        <meta
            v-if="$page.props.event_logo"
            property="og:image"
            head-key="og:image"
            :content="$page.props.event_logo"
        />
        <meta
            v-if="$page.props.event_logo"
            property="og:image:secure_url"
            head-key="og:image:secure_url"
            :content="$page.props.event_logo"
        />
        <meta
            v-if="!$page.props.event_logo"
            property="og:image"
            content="https://roadbookrally.com/img/faq/og_rbr.jpg"
            head-key="og:image"
        />
        <meta
            v-if="!$page.props.event_logo"
            property="og:image:secure_url"
            head-key="og:image:secure_url"
            content="https://roadbookrally.com/img/faq/og_rbr.jpg"
        />
    </Head>
    <TopMenuFullWidth></TopMenuFullWidth>
    <header
        v-if="$slots.header"
        class="shadow"
        :class="topHeaderBgClass"
    >
        <div
            class="mx-auto flex items-center justify-between px-4 py-2 sm:px-4 md:py-3 xl:px-6 2xl:px-8"
            :class="{
                'max-w-7xl': topHeaderFullWidth ? false : true,
            }"
        >
            <div class="min-w-0 flex-1">
                <div class="flex items-center space-x-2 py-2">
                    <slot name="header" />
                </div>
            </div>
            <div class="ml-4 gap-2">
                <slot name="buttons" />
            </div>
        </div>
    </header>
    <div :class="htmlClasses">
        <slot />
    </div>
    <Footer></Footer>
</template>
