<script setup>
import { Popover, PopoverButton, PopoverOverlay, PopoverPanel } from "@headlessui/vue";
import MobileNavIcon from "./assets/MobileNavIcon.vue";
import MobileNavLink from "./MobileNavLink.vue";
import { router } from "@inertiajs/vue3";

defineProps(["menuItems"]);

const switchToTeam = (team) => {
    router.put(
        route("current-team.update"),
        {
            team_id: team.id,
        },
        {
            preserveState: false,
        },
    );
};

const logout = () => {
    router.post(route("logout"));
};
</script>

<template>
    <Popover v-slot="{ open }">
        <PopoverButton
            class="relative z-10 flex size-8 items-center justify-center outline-none [&:not(:focus-visible)]:focus:outline-none"
            aria-label="Toggle Navigation"
        >
            <MobileNavIcon :open="open" />
        </PopoverButton>
        <transition
            enter-active-class="duration-150 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-150 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <PopoverOverlay class="fixed inset-0 bg-slate-300/50" />
        </transition>
        <transition
            enter-active-class="duration-150 ease-out"
            enter-from-class="opacity-0 scale-95"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="duration-100 ease-in"
            leave-from-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-95"
        >
            <PopoverPanel
                class="absolute inset-x-0 top-full mt-4 flex origin-top flex-col space-y-0.5 rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
            >
                <div
                    v-if="!$page.props.user"
                    class="pb-4"
                >
                    <!--                    nav ielogojies-->
                    <MobileNavLink
                        v-if="!$page.props.user"
                        href="/login"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('login'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('login'),
                        }"
                    >
                        Log in
                    </MobileNavLink>
                    <MobileNavLink
                        v-if="!$page.props.user"
                        href="/register"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('register'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('register'),
                        }"
                    >
                        Register
                    </MobileNavLink>
                    <div class="pt-4">
                        <hr />
                    </div>
                </div>

                <template v-for="item in menuItems">
                    <MobileNavLink
                        v-if="item.authenticated ? $page.props.user : true"
                        :key="item.route"
                        :href="route(item.route)"
                        class="block lg:hidden"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900': route().current(
                                item.route,
                            ),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current(item.route),
                        }"
                    >
                        {{ item.title }}
                    </MobileNavLink>
                </template>
                <div
                    v-if="$page.props.user"
                    class="space-y-0.5"
                >
                    <div class="px-2 pt-6 text-xs text-gray-400">Organizer tools</div>
                    <hr />

                    <MobileNavLink
                        :href="route('my-events')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('my-events'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('my-events'),
                        }"
                    >
                        My Events
                    </MobileNavLink>
                    <MobileNavLink
                        :href="route('my-tracks')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('my-tracks'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('my-tracks'),
                        }"
                    >
                        My Tracks
                    </MobileNavLink>
                    <MobileNavLink
                        :href="route('sales')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('sales'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('sales'),
                        }"
                    >
                        Sales
                    </MobileNavLink>

                    <!-- Team Management -->
                    <template v-if="$page.props.jetstream.hasTeamFeatures">
                        <div class="px-2 pt-6 text-xs text-gray-400">Manage Organizer Team</div>
                        <hr />

                        <!-- Team Settings -->
                        <MobileNavLink
                            :href="route('teams.show', $page.props.user.current_team)"
                            :class="{
                                'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                    route().current('teams.show'),
                                'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                    !route().current('teams.show'),
                            }"
                        >
                            Team Settings
                        </MobileNavLink>

                        <MobileNavLink
                            v-if="$page.props.jetstream.canCreateTeams"
                            :href="route('teams.create')"
                            :class="{
                                'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                    route().current('teams.create'),
                                'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                    !route().current('teams.create'),
                            }"
                        >
                            Create New Team
                        </MobileNavLink>

                        <!-- Team Switcher -->
                        <div class="px-2 pt-6 text-xs text-gray-400">Switch Organizer Teams</div>
                        <hr />

                        <template
                            v-for="team in $page.props.user.all_teams"
                            :key="'org_team_id' + team.id"
                        >
                            <form @submit.prevent="switchToTeam(team)">
                                <MobileNavLink
                                    as="button"
                                    type="submit"
                                >
                                    <div class="flex items-center">
                                        <svg
                                            v-if="team.id == $page.props.user.current_team_id"
                                            class="mr-2 size-5 text-green-400"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <div>{{ team.name }}</div>
                                    </div>
                                </MobileNavLink>
                            </form>
                        </template>
                    </template>

                    <div class="px-2 pt-6 text-xs text-gray-400">My Account</div>

                    <div class="px-2">
                        <div class="text-base font-medium text-gray-400">
                            {{ $page.props.user.name }}
                        </div>
                        <div class="text-sm font-medium text-gray-400">
                            {{ $page.props.user.email }}
                        </div>
                    </div>
                    <hr />

                    <MobileNavLink
                        :href="route('dashboard')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('dashboard'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('dashboard'),
                        }"
                    >
                        Dashboard
                    </MobileNavLink>
                    <MobileNavLink
                        :href="route('my-ride-history')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('my-ride-history'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('my-ride-history'),
                        }"
                    >
                        My Ride History
                    </MobileNavLink>
                    <MobileNavLink
                        :href="route('my-vehicles')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('my-vehicles'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('my-vehicles'),
                        }"
                    >
                        My Vehicles
                    </MobileNavLink>
                    <MobileNavLink
                        :href="route('purchases')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('purchases'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('purchases'),
                        }"
                    >
                        Purchases
                    </MobileNavLink>
                    <MobileNavLink
                        :href="route('profile.show')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('profile.show'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('profile.show'),
                        }"
                    >
                        Profile
                    </MobileNavLink>

                    <MobileNavLink
                        v-if="$page.props.jetstream.hasApiFeatures"
                        :href="route('api-tokens.index')"
                        :class="{
                            'bg-gray-100 font-medium text-gray-900 visited:text-gray-900':
                                route().current('api-tokens.index'),
                            'font-normal text-slate-700 visited:text-slate-700 hover:bg-slate-200 hover:text-slate-900':
                                !route().current('api-tokens.index'),
                        }"
                    >
                        API Tokens
                    </MobileNavLink>

                    <!-- Authentication -->
                    <form
                        method="POST"
                        @submit.prevent="logout"
                    >
                        <MobileNavLink
                            as="button"
                            type="submit"
                            class="mt-4"
                        >
                            Log Out
                        </MobileNavLink>
                    </form>
                </div>
            </PopoverPanel>
        </transition>
    </Popover>
</template>
