<script setup>
import NavLink from "./NavLink.vue";
import Button from "./Button.vue";
import MobileNavigation from "./MobileNavigation.vue";
import Dropdown from "./Dropdown.vue";
import DropdownLink from "./DropdownLink.vue";
import { router, usePage } from "@inertiajs/vue3";
import LogoBlock from "./LogoBlock.vue";

const menuItems = [
    {
        title: "Events",
        route: "events",
        authenticated: false,
    },
    {
        title: "Tracks",
        route: "tracks",
        authenticated: false,
    },
    {
        title: "Pricing",
        route: "pricing",
        authenticated: false,
    },
    {
        title: "FAQ",
        class: usePage().props.user ? "lg:hidden xl:block" : "",
        route: "faq",
        authenticated: false,
    },
    {
        title: "Contact us",
        class: usePage().props.user ? "block sm:hidden lg:hidden 2xl:block" : "",
        route: "contact-us",
        authenticated: false,
    },
];

const switchToTeam = (team) => {
    router.put(
        route("current-team.update"),
        {
            team_id: team.id,
        },
        {
            preserveState: false,
        },
    );
};

const logout = () => {
    router.post(route("logout"));
};

let isLocalDebug = false;
if (import.meta.env.VITE_APP_ENV === "local") {
    isLocalDebug = true;
}
</script>

<template>
    <header class="bg-slate-50 p-3 sm:px-6 lg:px-8">
        <nav class="relative z-40 flex justify-between">
            <div class="flex items-center space-x-10">
                <LogoBlock />
                <div class="hidden md:flex md:gap-x-6">
                    <template v-for="item in menuItems">
                        <NavLink
                            v-if="item.authenticated ? $page.props.user : true"
                            :href="route(item.route)"
                            :class="
                                item.class +
                                ' ' +
                                (isLocalDebug
                                    ? route().current(item.route)
                                        ? 'bg-red-200 text-slate-900 visited:text-slate-900 hover:bg-red-200 hover:text-slate-900'
                                        : 'text-red-500 hover:text-red-900 visited:text-red-500 hover:bg-red-200'
                                    : route().current(item.route)
                                      ? 'bg-slate-200 text-slate-900 visited:text-slate-900 hover:bg-slate-200 hover:text-slate-900'
                                      : 'text-slate-700 hover:text-slate-900 visited:text-slate-700 hover:bg-slate-200')
                            "
                        >
                            {{ item.title }}
                        </NavLink>
                    </template>
                </div>
            </div>
            <div class="flex items-center gap-x-0 sm:gap-x-4 md:gap-x-6">
                <template v-if="$page.props.user">
                    <Dropdown
                        align="right"
                        width="48"
                        class="hidden lg:flex"
                    >
                        <template #trigger>
                            <span class="inline-flex rounded-md">
                                <button
                                    type="button"
                                    class="inline-flex items-center rounded-md border border-transparent bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition hover:text-gray-700 focus:outline-none"
                                >
                                    Organizer tools
                                    <svg
                                        class="-mr-0.5 ml-2 size-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </span>
                        </template>
                        <template #content>
                            <div class="block px-4 py-2 text-xs text-gray-400">Organizer tools</div>

                            <DropdownLink :href="route('my-events')"> My Events</DropdownLink>

                            <div class="border-t border-gray-100" />

                            <DropdownLink :href="route('my-tracks')"> My Tracks</DropdownLink>

                            <div class="border-t border-gray-100" />

                            <DropdownLink :href="route('sales')"> Sales </DropdownLink>
                        </template>
                    </Dropdown>
                    <Dropdown
                        v-if="$page.props.jetstream.hasTeamFeatures"
                        align="right"
                        width="60"
                        class="hidden lg:flex"
                    >
                        <template #trigger>
                            <span class="inline-flex rounded-md">
                                <button
                                    type="button"
                                    class="inline-flex items-center rounded-md border border-transparent bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition hover:bg-gray-50 hover:text-gray-700 focus:bg-gray-50 focus:outline-none active:bg-gray-50"
                                >
                                    {{ $page.props.user.current_team.name }}

                                    <svg
                                        class="-mr-0.5 ml-2 size-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </span>
                        </template>

                        <template #content>
                            <div class="w-60">
                                <!-- Team Management -->
                                <template v-if="$page.props.jetstream.hasTeamFeatures">
                                    <div class="block px-4 py-2 text-xs text-gray-400">
                                        Manage Organizer Team
                                    </div>

                                    <!-- Team Settings -->
                                    <DropdownLink :href="route('teams.show', $page.props.user.current_team)">
                                        Team Settings
                                    </DropdownLink>

                                    <DropdownLink
                                        v-if="$page.props.jetstream.canCreateTeams"
                                        :href="route('teams.create')"
                                    >
                                        Create New Organizer Team
                                    </DropdownLink>

                                    <div class="border-t border-gray-100" />

                                    <!-- Team Switcher -->
                                    <div class="block px-4 py-2 text-xs text-gray-400">
                                        Switch Organizer Teams
                                    </div>

                                    <template
                                        v-for="team in $page.props.user.all_teams"
                                        :key="team.id"
                                    >
                                        <form @submit.prevent="switchToTeam(team)">
                                            <DropdownLink as="button">
                                                <div class="flex items-center">
                                                    <svg
                                                        v-if="team.id == $page.props.user.current_team_id"
                                                        class="mr-2 size-5 text-green-400"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        stroke="currentColor"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <path
                                                            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                                        />
                                                    </svg>
                                                    <div>
                                                        {{ team.name }}
                                                    </div>
                                                </div>
                                            </DropdownLink>
                                        </form>
                                    </template>
                                </template>
                            </div>
                        </template>
                    </Dropdown>
                    <Dropdown
                        align="right"
                        width="48"
                        class="hidden lg:flex"
                    >
                        <template #trigger>
                            <button
                                v-if="$page.props.jetstream.managesProfilePhotos"
                                class="flex rounded-full border-2 border-transparent text-sm transition focus:border-gray-300 focus:outline-none"
                            >
                                <img
                                    class="size-8 rounded-full object-cover"
                                    :src="$page.props.user.profile_photo_url"
                                    :alt="$page.props.user.name"
                                />
                            </button>

                            <span
                                v-else
                                class="inline-flex rounded-md"
                            >
                                <button
                                    type="button"
                                    class="inline-flex items-center rounded-md border border-transparent bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition hover:text-gray-700 focus:outline-none"
                                >
                                    {{ $page.props.user.name }}

                                    <svg
                                        class="-mr-0.5 ml-2 size-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </span>
                        </template>
                        <template #content>
                            <!-- Account Management -->
                            <div class="block px-4 py-2 text-xs text-gray-400">My Account</div>

                            <DropdownLink :href="route('my-ride-history')"> My Ride History</DropdownLink>
                            <DropdownLink :href="route('my-vehicles')"> My Vehicles</DropdownLink>
                            <DropdownLink :href="route('purchases')"> Purchases </DropdownLink>
                            <DropdownLink :href="route('profile.show')"> Profile</DropdownLink>

                            <DropdownLink
                                v-if="$page.props.jetstream.hasApiFeatures"
                                :href="route('api-tokens.index')"
                            >
                                API Tokens
                            </DropdownLink>

                            <div class="border-t border-gray-100" />

                            <!-- Authentication -->
                            <form @submit.prevent="logout">
                                <DropdownLink as="button"> Log Out</DropdownLink>
                            </form>
                        </template>
                    </Dropdown>
                    <Button
                        v-if="$page.props.user"
                        href="/dashboard"
                        color="primary"
                        variant="solid"
                        class="hidden lg:flex"
                    >
                        Dashboard
                    </Button>
                </template>
                <template v-if="!$page.props.user">
                    <div class="hidden md:block">
                        <NavLink
                            href="/login"
                            :class="
                                route().current('login')
                                    ? 'bg-slate-200 text-slate-900 visited:text-slate-900 hover:bg-slate-200 hover:text-slate-900'
                                    : 'text-slate-700 hover:text-slate-900 visited:text-slate-700 hover:bg-slate-200'
                            "
                            >Log in
                        </NavLink>
                    </div>
                    <Button
                        href="/register"
                        color="primary"
                        class="hidden lg:block"
                    >
                        <span> Register </span>
                    </Button>
                </template>
                <div class="-mr-1 lg:hidden">
                    <MobileNavigation :menu-items="menuItems" />
                </div>
            </div>
        </nav>
    </header>
    <hr />
</template>
